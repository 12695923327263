<template>
    <div>
        <div v-if="!fetchingOrgUsers" class="d-flex flex-wrap align-center">
            <v-checkbox v-for="(item, index) in Object.entries(userCheckboxBinders)" :key="index" class="mx-4" v-model="item[1].status">
                <template v-slot:label>
                    <div class="d-flex align-center">
                        <v-icon class="mr-1" small color="secondary">{{ item[1].icon }}</v-icon>
                        <span class="secondary--text caption text-uppercase">{{ item[0] }}</span>
                    </div>
                </template>
            </v-checkbox>
            <v-spacer />
            <v-text-field
                class="px-4"
                v-show="expand && orgUsers.length"
                placeholder="Search for a member..."
                autofocus
                v-model="search"
                outlined
                hide-details
                dense></v-text-field>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="$store.dispatch('orgStore/fetchOrgUsers', $route.params.oid)" text icon>
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </template>
                <span>refresh</span>
            </v-tooltip>
        </div>
        <v-divider class="mb-5"></v-divider>
        <div v-if="!fetchingOrgUsers">
            <v-data-iterator v-if="orgUsers.length" :items="filteredOrgUsers" :search="search">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="item.uid">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon x-large>person</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.full_name }}
                                            <v-chip x-small v-if="item.is_active" color="success">Active</v-chip>
                                            <v-chip x-small v-else-if="!item.is_active" color="error">Inactive</v-chip>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="caption mr-3 font-weight-bold">{{ item.email }}</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item-content>
                                        <v-list-item-subtitle style="width: 30%">
                                            <ComponentWithStyledBorder>
                                                <div class="d-flex align-center pa-1">
                                                    <v-icon v-if="item.role === roleTypes.ORG_MANAGER">corporate_fare</v-icon>
                                                    <v-icon v-else-if="item.role === roleTypes.ORG_FACULTY">supervisor_account</v-icon>
                                                    <v-icon v-else>person</v-icon>
                                                    <span class="ml-1">{{ item.role }}</span>
                                                </div>
                                            </ComponentWithStyledBorder>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                            <v-list-item-action v-if="isOrgManager">
                                <div class="d-flex align-center">
                                    <UserActions
                                        :uid="item.uid.toString()"
                                        :ucid="item.ucid.toString()"
                                        :isActiveUser="item.is_active"
                                        :userName="item.full_name"
                                        :hierarchicalLevel="nuvolosObjectTypes.ORGANIZATION"
                                        :role="item.role"
                                        :orgName="$store.getters['orgStore/orgLongName']"></UserActions>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <div v-if="isOrgManager">
                        <invite-btn @goToInvite="$emit('goToInvite', { value: true })" />
                    </div>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                </template>
            </v-data-iterator>

            <div v-else>
                <v-banner two-line>
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">info</v-icon>
                    </v-avatar>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold secondary--text mb-1">No users found</span>
                        <span>
                            Currently this organization has no additional users.
                            <span v-if="isOrgManager">
                                You can add new organization members via the
                                <v-icon small color="primary">add</v-icon>
                                <span class="font-weight-bold secondary--text">invite</span>
                                button above.
                            </span>
                        </span>
                    </div>
                </v-banner>
            </div>
            <v-alert :value="this.error" color="error" icon="warning" class="mt-4" text>
                <span class="font-weight-bold">An error has occurred</span>
                <div>
                    The user could not be revoked. To learn more about user revoke issues, check the troubleshooting documentation
                    <v-btn
                        class="font-weight-bold"
                        small
                        outlined
                        text
                        color="error"
                        href="https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-revoke-a-user-role"
                        target="_blank">
                        here
                    </v-btn>
                </div>
            </v-alert>
        </div>
        <div v-else>
            <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import InviteBtn from '@/components/InviteBtn.vue'
const UserActions = () => import('@/components/UserActions')
const ComponentWithStyledBorder = () => import('@/components/ComponentWithStyledBorder')
export default {
    components: { UserActions, ComponentWithStyledBorder, InviteBtn },
    mixins: [enumsData],
    data() {
        return {
            search: '',
            error: false,
            expand: true,
            userName: '',
            userUcid: '',
            headers: [
                { text: 'User name', align: 'left', value: 'full_name' },
                { text: 'Email', align: 'left', value: 'email' },
                { text: 'Role', align: 'left', value: 'role' },
                { text: 'Status', align: 'left', value: 'status' },
                { text: 'Actions', align: 'center', value: 'actions' }
            ],
            itemsPerPageOptions: [-1, 100],
            userCheckboxBinders: {
                managers: { status: true, icon: 'corporate_fare' },
                faculty: { status: true, icon: 'supervisor_account' },
                members: { status: true, icon: 'person' }
            }
        }
    },
    computed: {
        ...mapState('orgStore', ['orgUsers', 'fetchingOrgUsers']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapGetters('orgStore', ['isOrgManager']),
        filteredOrgUsers() {
            if (this.orgUsers) {
                const displayedUsers = []
                if (this.userCheckboxBinders.managers.status) {
                    displayedUsers.push(this.roleTypes.ORG_MANAGER)
                }
                if (this.userCheckboxBinders.faculty.status) {
                    displayedUsers.push(this.roleTypes.ORG_FACULTY)
                }
                if (this.userCheckboxBinders.members.status) {
                    displayedUsers.push(this.roleTypes.ORG_MEMBER)
                }
                const filteredUsers = this.orgUsers.filter(user => displayedUsers.includes(user.role))
                return filteredUsers
            }
            return []
        }
    }
}
</script>
